import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DateRangeInterface } from "src/app/shared/interfaces/date-range.interface";
import { LogsFiltersStateInterface } from "./interfaces/logs-filters-state.interface";

type TAbstractState = LogsFiltersStateInterface<ReadonlyArray<string | number>>;

@Injectable()
export abstract class AbstractFiltersStore<
  TState extends TAbstractState = TAbstractState,
  TCategories extends ReadonlyArray<string> | ReadonlyArray<number> = ReadonlyArray<string> | ReadonlyArray<number>,
> {
  abstract state$: BehaviorSubject<TState>;

  getState$(): Observable<TState> {
    return this.state$.asObservable();
  }

  updateDevice(deviceId: string): void {
    this.state$.next({
      ...this.state$.value,
      device: deviceId,
    });
  }

  updateDateRange(dateRange: DateRangeInterface): void {
    this.state$.next({
      ...this.state$.value,
      start: dateRange.dateFrom.toISOString(),
      end: dateRange.dateTo.toISOString(),
    });
  }

  updateLimit(limit: number): void {
    this.state$.next({
      ...this.state$.value,
      limit,
    });
  }

  updateCategories(categories: TCategories): void {
    this.state$.next({
      ...this.state$.value,
      categories,
    });
  }

  resetCategories(): void {
    this.state$.next({
      ...this.state$.value,
      categories: [],
    });
  }

  updateSearch(search: string): void {
    this.state$.next({
      ...this.state$.value,
      search,
    });
  }
}
