<ng-scrollbar
  [autoHeightDisabled]="false"
  [track]="'horizontal'"
  [visibility]="'always'"
  class="logs-kawasaki-filters-scrollbar"
>
  <div class="logs-kawasaki-filters__grid" *ngIf="shouldDisplay">
    <ng-container *ngFor="let category of categories">
      @let categoryIcon = iconParser(category.code);
      <div class="logs-kawasaki-filters__element">
        <app-form-checkbox
          [formControl]="$any(categoriesFormGroup.controls[category.code])"
        >
          <mat-icon
            [style.color]="categoryIcon.color"
            class="logs-kawasaki-filters__icon"
          >
            {{ categoryIcon.icon }}
          </mat-icon>
          <span
            class="logs-kawasaki-filters__counter"
            [style.color]="categoryIcon.color"
          >
            {{ category.count }}
          </span>
          <span>{{ category.name }}</span>
        </app-form-checkbox>
      </div>
    </ng-container>
  </div>
</ng-scrollbar>
