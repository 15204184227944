import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AbstractFiltersStore } from '../../abstract-filters-store.store';
import { LogsKawasakiFiltersStateInterface } from '../../interfaces/logs-filters-state.interface';

@Injectable()
export class LogsKawasakiFiltersStore extends AbstractFiltersStore<LogsKawasakiFiltersStateInterface, number[]> {
  state$ = new BehaviorSubject<LogsKawasakiFiltersStateInterface>({
    device: '',
    start: null,
    end: null,
    categories: null,
    search: null,
    limit: null,
    page: 1,
    error_code: null,
  });

  updateErrorGroup(errorCode: string): void {
    this.state$.next({
      ...this.state$.value,
      error_code: errorCode,
    });
  }
}
