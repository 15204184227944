import { Injectable } from '@angular/core';
import { UserConfigTypeEnum } from '../../../../../../shared/enums/user-config-type.enum';
import { WarningLevelUREnum } from '../../../../../../shared/enums/warning-level-ur.enum';
import { UserConfigInterface } from '../../../../../../shared/interfaces/user-config.interface';
import { ApiResourceLogsKawasakiService } from '../../../../../../shared/modules/api/services/api-resource-logs/api-resource-logs-kawasaki/api-resource-logs-kawasaki.service';
import {
  ApiResourceUserConfigService,
} from '../../../../../../shared/modules/api/services/api-resource-user-config/api-resource-user-config.service';
import { AbstractFiltersService } from '../../../abstract-filters-service.service';
import { LogsKawasakiCategoryInterface } from '../../../interfaces/logs-category.interface';
import { LogsKawasakiCategoriesService } from '../../services/logs-kawasaki-categories/logs-kawasaki-categories.service';

@Injectable({
  providedIn: 'root',
})
export class LogsKawasakiFiltersService extends AbstractFiltersService<number, LogsKawasakiCategoryInterface> {
  constructor(
    apiResourceLogsService: ApiResourceLogsKawasakiService,
    apiResourceUserConfigService: ApiResourceUserConfigService,
    logsCategoriesService: LogsKawasakiCategoriesService,
  ) {
    super(
      UserConfigTypeEnum.LOGS_KAWASAKI_PAGE_CATEGORIES,
      apiResourceLogsService,
      apiResourceUserConfigService,
      logsCategoriesService,
    );
  }

  protected cast(val: string): number {
    return Number(val);
  }

  protected getDefaultConfig(): Omit<UserConfigInterface<number[]>, 'id'> {
    return {
      config_type: this.configType,
      label: this.configLabel,
      config: [WarningLevelUREnum.FAULT, WarningLevelUREnum.VIOLATION, WarningLevelUREnum.WARNING],
    };
  }
}
